import styled from "styled-components";
import { device, StyleFontHeader } from "../../Layout/theme";

export const StyledAboutFeature = styled.div`
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color_background_tertiary};

  .container {
    position: relative;
  }

  .title {
    ${StyleFontHeader};
    color: ${({ theme }) => theme.color_text};
    font-size: 200px;
    line-height: 200px;
    letter-spacing: 0.02em;
  }

  .logo {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(70%, -50%);
  }

  @media ${device.small} {
    .title {
      font-size: 100px;
    }

    .logo {
      width: 60px;
    }
  }
`;
