import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetRankWithSuffix } from "../../../../../hooks/use-get-rank-with-suffix";
import { SeasonPointsBreakdown } from "../../../../../services/championsQueueData/index.type";
import { StyledAboutSeasonPointsBreakdown } from "./style";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image-es5";
import { sliceIntoChunks } from "../../../../../helpers/utils";

export type Props = {
  seasonPoints: SeasonPointsBreakdown[]
};

const AboutSeasonPointsBreakdown: React.FC<Props> = ({ seasonPoints }: Props) => {
  const { t } = useTranslation();
  const getRankWithSuffix = useGetRankWithSuffix();

  const { patternImage } = useStaticQuery(
    graphql`
      query {
        patternImage: file(relativePath: { eq: "title-pattern-wide.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
            )
          }
        }
      }
    `
  );
  const image = getImage(patternImage);
  const bgImage = convertToBgImage(image);

  const columns = sliceIntoChunks(seasonPoints, 3);

  return (
    <StyledAboutSeasonPointsBreakdown>
      <BackgroundImage Tag="div" className="header" {...bgImage}>
        {t("about:seasonPoints.title")}
      </BackgroundImage>
      <div className="points-table">
        {columns.map((column, i) => (
          <div key={i} className="column">
            {column.map((seasonPoint, j) => (
              <div key={j} className="row">
                <span className="rank">
                  {seasonPoint.minRank === seasonPoint.maxRank
                    ? getRankWithSuffix(seasonPoint.minRank)
                    : t("about:seasonPoints.pointRange", {
                      minRank: getRankWithSuffix(seasonPoint.minRank),
                      maxRank: getRankWithSuffix(seasonPoint.maxRank)
                    })}
                </span>
                <span className="divider" />
                <span className="points">{seasonPoint.points}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="explainer">{t("about:seasonPoints.explainer")}</div>
    </StyledAboutSeasonPointsBreakdown>
  );
};

export default AboutSeasonPointsBreakdown;
