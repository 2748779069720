import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyledAboutFeature } from "./style";

const AboutFeature: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledAboutFeature>
      <div className="container">
        <h1 className="title">{t("nav.about")}</h1>
        <StaticImage
          className="logo"
          src="../../../images/champions-queue-logo-feature.svg"
          alt={t("nav.logo")}
        />
      </div>
    </StyledAboutFeature>
  );
};

export default AboutFeature;
