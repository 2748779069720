import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { Fragment } from "react";
import AboutPrizePool from "../components/Champions/About/PrizePool";
import AboutSchedule from "../components/Champions/About/Schedule";
import AboutSeasonPointsBreakdown from "../components/Champions/About/Season/PointsBreakdown";
import AboutExplainer from "../components/Explainer/About";
import AboutFeature from "../components/Feature/About";
import InformError from "../components/Inform/Error";
import InformLoading from "../components/Inform/Loading";
import Layout from "../components/Layout";
import { StyledLayoutContentArea } from "../components/Layout/style";
import { useFetch } from "../hooks/use-fetch";
import { fetchAboutConfig } from "../services/championsQueueData";

const AboutPage: React.FC = () => {
  const { t } = useTranslation();

  const { isLoading, response, error } = useFetch(fetchAboutConfig, []);

  return (
    <Layout>
      <GatsbySeo title={t("title")} />
      <AboutFeature />
      <StyledLayoutContentArea>
        {isLoading && <InformLoading />}
        {error && <InformError error={error} />}
        {response && (
          <Fragment>
            <AboutExplainer />
            <AboutSchedule schedule={response.schedule} />
            <AboutSeasonPointsBreakdown seasonPoints={response.seasonPoints} />
            {response.prizePools.map((prizePool, index, array) => (
              <AboutPrizePool key={prizePool.title} prizePool={prizePool} className={index === array.length - 1 ? 'last-prize-pool' : undefined} />
            ))}
            {/* TODO: if it turns out these sections are no longer needed, delete
                      the below commented out components and delete their files as
                      well. */}
            {/* <AboutQualifications />
            <AboutRules /> */}
          </Fragment>
        )}
      </StyledLayoutContentArea>
    </Layout>
  );
};

export default AboutPage;

export const query = graphql`
  query ($language: String!) {
    ...TranslationQueryFragment
  }
`;
