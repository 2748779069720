import styled from "styled-components";
import { device, StyleFontHeader, TextTransformUppercase } from "../../../../Layout/theme";

export const StyledAboutSeasonPointsBreakdown = styled.div`
  padding-bottom: 80px;

  .header {
    ${StyleFontHeader}
    font-size: 57px;
    padding: 40px;
  }

  .points-table {
    display: flex;
    align-items: flex-start;
    background-color: ${({ theme }) => theme.color_background_tertiary};

    .column {
      padding: 30px 40px;
      width: calc(100% / 3);
      position: relative;

      &:not(:first-child) {
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 30px;
          bottom: 30px;
          width: 1px;
          background-color: ${({ theme }) => theme.color_rule};
        }
      }

      .row {
        display: flex;
        align-items: center;

        .rank {
          ${TextTransformUppercase}
          flex-grow: 0;
          font-weight: bold;
          font-size: 20px;
        }

        .divider {
          flex-grow: 1;
          height: 1px;
          background-color: ${({ theme }) => theme.color_rule};
          margin: 0 20px;
        }

        .points {
          ${StyleFontHeader}
          font-size: 55px;
          flex-grow: 0;
        }
      }
    }
  }

  .explainer {
    ${TextTransformUppercase}
    background-color: ${({ theme }) => theme.color_background_tertiary};
    color: ${({ theme }) => theme.color_accent};
    font-weight: bold;
    font-size: 20px;
    padding: 0 40px 30px;
  }

  @media ${device.large} {
    margin: ${({ theme }) => theme.gutter_size_large};
  }

  @media ${device.medium} {
    margin: ${({ theme }) => theme.gutter_size_medium};
  }

  @media ${device.small} {
    margin: ${({ theme }) => theme.gutter_size_small};

    .points-table {
      flex-direction: column;

      .column {
        width: 100%;
        padding: 0 40px;

        &:not(:first-child) {
          &::before {
            display: none;
          }
        }
      }
    }
  }
`;
